import React from 'react'
import SiteLayout from '../components/SiteLayout'
import { Row } from 'antd'

import CURLCall from "../images/curl-call-example.png"
import apiReference_Icon from "../images/api-reference-icon.png"
import apiReference from '../images/api-reference-img.png'



const ApiReference = ({ location }) => (
  <SiteLayout location={location}>
    <Row type="flex" justify="center">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">
        <div className="flex-item">
          <h1 style={{ textAlign: 'center' }}>API Catalogue</h1>
          <h2 style={{ textAlign: 'center' }} className="small">Check out our Catalogue and choose the APIs that<br></br>will enable you to develop new solutions.</h2>
        </div>
      </div>
    </Row>
    <Row type="flex">
      <div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

        <div className="flex-item">
          <img src={apiReference_Icon} className="title-icon" alt="API Reference icon" />
          <h2 className="title">Find the right API for you</h2>
          <p>Strands FM suite provides much more than the basic transactions, products and users APIs. </p>
          <p>Get access to more than 30 REST APIs to manage budgets, insights, get cashflow projections or set up recurring payments, all fully documented with Swagger. </p>
          <code className="tutorials">curl -X GET -H 'Accept: application/json' -H 'authorization: Bearer &lt;bearer_here&gt;' 'https://fm.strands.com/api/insights’</code>
        </div>

        <div className="flex-item img-container">
          <img src={CURLCall} alt="CURL call example" />
        </div>

      </div>
    </Row>

    <h2 style={{ textAlign: 'center' }} className="small">Ready to build powerful products?</h2>
    <p style={{ textAlign: 'center' }}> Create a developer account, explore, and begin<br></br> integrating our APIs with a few lines of code.</p>

    <p style={{ textAlign: 'center' }} ><a href="/become-partner" className="btn">Become a Partner</a></p>

  </SiteLayout>
)

export default ApiReference
